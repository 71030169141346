@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body{
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  margin: 0px;
  font-family: 'Lato', 'sans-serif';
}

/* .container{
  margin: 30px auto;
  width: 350px;
} */

h1{
  letter-spacing: 1px;
  margin: 0px;
}